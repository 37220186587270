import { AppointmentOptions, StartLobbyState } from "../types";

export function resolveLobby(appointmentOptions: AppointmentOptions, lobbyState: StartLobbyState): void {
    switch (lobbyState) {
        case StartLobbyState.Nobody:
            appointmentOptions.sendUsersToLobby = false;
            appointmentOptions.sendGuestsToLobby = false;
            break;
        case StartLobbyState.Guests:
            appointmentOptions.sendUsersToLobby = false;
            appointmentOptions.sendGuestsToLobby = true;
            break;
        case StartLobbyState.All:
            appointmentOptions.sendUsersToLobby = true;
            appointmentOptions.sendGuestsToLobby = true;
            break;
    }
}

export function resolveLobbyState(appointmentOptions: AppointmentOptions): StartLobbyState {
    if (appointmentOptions.sendUsersToLobby && appointmentOptions.sendGuestsToLobby) {
        return StartLobbyState.All;
    }
    if (!appointmentOptions.sendUsersToLobby && appointmentOptions.sendGuestsToLobby) {
        return StartLobbyState.Guests;
    }
    if (!appointmentOptions.sendUsersToLobby && !appointmentOptions.sendGuestsToLobby) {
        return StartLobbyState.Nobody;
    }

    console.warn("unknown lobby state. return Nobody", appointmentOptions);
    return StartLobbyState.Nobody;
}
